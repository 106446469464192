import "./footer.scss"

import React from "react"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-sm-5 col-custom-1">
              <address>
                RASKA <br />
                Vegurin langi 31 <br />
                FO-188 Hoyvík <br />
                Tel. (+298) 309000 <br />
                Fax. (+298) 309001
                <br />
                <a href="mailto:mbm@mbm.fo">mbm(kurla)mbm.fo</a>
              </address>
            </div>
            <div className="col-sm-5 col-custom-2">
              <address>
                <br />
                Skrivstovan <br />
                Mánadag til fríggjadag <br />
                kl. 08:00 til kl. 16:00
              </address>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-custom-1">
              <a
                href="https://www.facebook.com/raska.fo"
                target="_blank"
                rel="noreferrer"
                className="footer__link"
              >
                Facebook
              </a>
            </div>
            <div className="col-6 col-custom-2">
              <a
                href="https://www.instagram.com/raska.mbm/"
                target="_blank"
                rel="noreferrer"
                className="footer__link"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="footer__copy">
        by{" "}
        <a href="https://hogra.fo/" target="_blank" rel="noreferrer">
          Hogra.fo
        </a>
      </p>
    </footer>
  )
}

export default Footer
