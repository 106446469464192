import React from "react"
import { CookiesProvider, withCookies } from "react-cookie"

import Header from "components/Header/Header"
import Footer from "components/Footer/Footer"
import CookieBaner from "components/CookieBaner/CookieBaner"

import "styles/app.scss"

const Layout = ({ children, isHome, isContest }) => {
  return (
    <CookiesProvider>
      <Header isHome={isHome} isContest={isContest} />
      <main>{children}</main>
      {!isContest && <Footer />}
      <CookieBaner />
    </CookiesProvider>
  )
}

export default withCookies(Layout)
